import React, { Component } from 'react';

export default class ImageSlideshow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            images: props.images,
            labels: props.labels,
            selected: 0
        }
    }

    // componentDidMount() {
    //     var newImages = [];
    //     var newLabels = [];
    //     // for (var i = 0; i < this.state.images; i++) {
    //     this.state.images.map((image, i) => {
    //         try {
    //             const src = require(image)
    //             newImages.push(image);
    //             newLabels.push(this.state.labels[i]);
    //         }
    //         catch (err) {
    //             console.log(err);
    //         }
    //     })

    //     // }
    //     setTimeout(() => {
    //         this.setState({
    //             images: newImages,
    //             labels: newLabels
    //         })
    //     }, 20)
    // }

    _c_buttons = () => this.state.labels.map((label, index) => {
        if (label != '' && label != null) {
            return this.state.selected === index ? (
                <div className='button_toggle' key={index} style={{ opacity: 1, fontWeight: 700 }}>
                    {label}
                </div>
            ) : (
                <div className='button_toggle' key={index} onClick={() => { this.setState({ selected: index }) }}>
                    {label}
                </div>
            )
        }
    })

    _c_image = () => {
        if (this.state.images != null) {
            return (
                <img src={this.state.images[this.state.selected]} />
            )
        }
    }

    render() {
        return (
            <div>
                <div className='button_toggle_container'>
                    {this._c_buttons()}
                </div>
                <div onClick={() => { this.props.previewFn(this.state.images[this.state.selected], null) }} className='ImageSlideshow_image'>
                    {this._c_image()}
                </div>
                {/* <img src={this.state.images[0]} /> */}
            </div>
        )
    }

}
