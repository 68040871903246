import { useEffect, useState } from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, onAuthStateChanged, signOut } from "firebase/auth";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


import './App.css';
import ImageSlideshow from './ImageSlideshow';

import data_general from './data/general.json';
// import data_population_0 from './data/population_0.json';
// import data from './data.json';

import parse from 'html-react-parser';


//// FIREBASE
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDPrXx2kpj9bdhntilOlqxZ2AadD9gtUjk",
  authDomain: "lauerkimlab.firebaseapp.com",
  projectId: "lauerkimlab",
  storageBucket: "lauerkimlab.appspot.com",
  messagingSenderId: "355368987735",
  appId: "1:355368987735:web:31fc80309e5718e46b8712",
  measurementId: "G-CL4EJ0ZB0S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://hbv.lauerkimlab.com/',
  // This must be true.
  handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.example.ios'
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12'
  // },
  // dynamicLinkDomain: 'example.page.link'
};
//// end of FIREBASE


const EligibleEmails = [
  'eliasishaklattouf@gmail.com',
  'elattouf@mgh.harvard.edu',
  'glauer@helix.mgh.harvard.edu',
  'jbolleke@its.jnj.com',
  'edetroye@its.jnj.com',
  'hdrescher@mgh.harvard.edu',
  'lbartsch1@mgh.harvard.edu',
  'dlieb@broadinstitute.org',
  'joerg.timm@med.uni-duesseldorf.de'
]


function App() {

  const [Data, setData] = useState(null);

  const [Populations, setPopulations] = useState([]);
  const [SelectedPopulation, SelectPopulation] = useState(null);
  const [Comparisons, setComparisons] = useState([]);
  const [SelectedComparison, SelectComparison] = useState(null);

  const [ImagePreview, setImagePreview] = useState(null);
  const [ImagePreview_htmlToParse, setImagePreview_htmlToPrase] = useState(null);
  const [ModalImagePreview, toggleModalImagePreview] = useState(false);

  const [LoadingSelecting, setLoadingSelecting] = useState(false);

  // const [PasswordPassed, setPasswordPassed] = useState(false);
  // const [Password, setPassword] = useState("");
  // const [WrongPassword, setWrongPassword] = useState(false);

  const [EmailPassed, setEmailPassed] = useState(null);
  const [Email, setEmail] = useState("");
  const [WrongEmail, setWrongEmail] = useState(false);
  const [LoadingEmail, setLoadingEmail] = useState(false);
  const [CheckYourEmail, setCheckYourEmail] = useState(false);


  const [ModalShown, toggleModal] = useState(false);

  // FIREBASE
  useEffect(() => {
    const auth = getAuth();
    const checkAuthStatus = onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        setEmailPassed(true);
      } else {
        // PROD
        setEmailPassed(false);
        // DEV
        // setEmailPassed(true);
      }
      return () => checkAuthStatus();
    });
  }, []);

  useEffect(() => {
    const auth = getAuth();
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = localStorage.getItem('emailForSignIn');
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          localStorage.removeItem('emailForSignIn');
          setEmailPassed(true);
        })
        .catch((error) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          console.log(error);
        });
    }
  }, [])

  // GETTING ALL POPULATIONS
  useEffect(() => {
    var allPopulations = [];
    for (var i = 0; i < data_general.data.length; i++) {
      allPopulations.push(data_general.data[i].population);
    }
    setPopulations(allPopulations)
  }, [])


  const returnOptions_populations = () => {
    return Populations.map((population, index) => <option key={index} value={index}>{population}</option>)
  }

  const returnOptions_comparisons = () => {
    return Comparisons.map((comparison, index) => <option key={index} value={index}>{comparison}</option>)
  }

  const returnDropdown_comparisons = () => {
    if (Comparisons.length != 0) {
      return (
        <>
          <div className="select_title">Select comparison:</div>
          <select onChange={(e) => { changeComparison(e) }}>
            <option selected disabled>Tap to select</option>
            {returnOptions_comparisons()}
          </select>
        </>
      )
    }
  }


  const modal_SelectPopulationComparison = () => {
    if (ModalShown === true) {
      return (
        <div className='modal_select' id='modal_select'>
          <div onClick={() => { button_toggleModal() }} className='modal_select_back' style={{ display: 'flex', alignItems: 'center' }}>
            <img src='/back.png' style={{ height: '18px', width: '18px', objectFit: 'contain', marginRight: "10px" }} /> Go Back
          </div>
          <div className="select_title">Select population:</div>

          <select onChange={(e) => { changePopulation(e) }}>
            <option selected disabled>Tap to select</option>
            {returnOptions_populations()}
          </select>


          {LoadingSelecting === true ?
            (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginTop: '50px' }}>
                <div className='loading'>Loading...</div>
              </div>
            )
            : (<></>)}

          {returnDropdown_comparisons()}
        </div>
      )
    }
  }


  const files = {
    "path_main": "/results/main/",

    "counts": "general_counts.png",
    "markers_supervised_antigens_clusters": "markers_supervised-antigens_clusters.png",
    "markers_supervised_antigens_antigens": "markers_supervised-antigens_antigens.png",
    "markers_supervised_antigens_statuses": "markers_supervised-antigens_statuses.png",
    "markers_supervised_statuses_clusters": "markers_supervised-statuses_clusters.png",
    "markers_supervised_statuses_antigens": "markers_supervised-statuses_antigens.png",
    "markers_supervised_statuses_statuses": "markers_supervised-statuses_statuses.png",
    "markers_unsupervised_clusters": "markers_unsupervised_clusters.png",
    "markers_unsupervised_antigens": "markers_unsupervised_antigens.png",
    "markers_unsupervised_statuses": "markers_unsupervised_statuses.png",
    "umap_clusters_cell_counts": "umap_clusters-cell_counts.png",
    "umap_clusters_patient_counts": "umap_clusters-patient_counts.png",
    "umap_clusters": "umap_clusters.png",
    "umap_antigens_cell_counts": "umap_antigens-cell_counts.png",
    "umap_antigens_patient_counts": "umap_antigens-patient_counts.png",
    "umap_antigens": "umap_antigens.png",
    "umap_patients": "umap_patients.png",
    "umap_statuses_cell_counts": "umap_statuses-cell_counts.png",
    "umap_statuses_patient_counts": "umap_statuses-patient_counts.png",
    "umap_statuses": "umap_statuses.png",
    "umap_tissue": "umap_tissue.png",
    "umap_tissues_cell_counts": "umap_tissues-cell_counts.png",
    "umap_tissues_patient_counts": "umap_tissues-patient_counts.png",
    "umap_studyID": "umap_studyID.png",
    "umap_studyID_cell_counts": "umap_studyID-cell_counts.png",
    "umap_studyID_patient_counts": "umap_studyID-patient_counts.png"
  }

  const div_content = () => {
    if (SelectedComparison == null) {
      return (
        <>
          {/* <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '20px' }}>
            <div onClick={() => { app_signOut() }} className='modal_select_back_signout'>
              Sign Out
            </div>
          </div> */}
          <div className='modal_select_back' style={{ color: '#AAA', marginBottom: '30px', width: '100%', textAlign: 'left', padding: '0px', display: 'flex', alignItems: 'center' }}>
            <div className='modal_select_back_child'>
              Main Population
            </div>
            <div onClick={() => { app_signOut() }} className='modal_select_back_signout'>
              Sign Out
            </div>
          </div>

          <div className='content_bigTitle'>
            HBV CD8 Blood & Liver
          </div>

          <div className='content_title'>General information</div>
          <img className='content_generalCount' src={files.path_main + 'general_counts.png'} />

          <div className='content_title'>UMAPS</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                files.path_main + files.umap_clusters,
                files.path_main + files.umap_patients,
                files.path_main + files.umap_tissue,
                files.path_main + files.umap_antigens,
                files.path_main + files.umap_statuses,
                files.path_main + files.umap_studyID,
              ]}
              labels={[
                "Clusters", "Patients", "Tissues", "Antigens", "Infection Statuses", "Study"
              ]}
            />
          </div>

          <div className='content_title'>Cell Counts</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                files.path_main + files.umap_clusters_cell_counts,
                files.path_main + files.umap_tissues_cell_counts,
                files.path_main + files.umap_antigens_cell_counts,
                files.path_main + files.umap_statuses_cell_counts,
                files.path_main + files.umap_studyID_cell_counts,
              ]}
              labels={[
                "Clusters", "Tissues", "Antigens", "Infection Statuses", "Study"
              ]}
            />
          </div>

          <div className='content_title'>Patient Counts</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                files.path_main + files.umap_clusters_patient_counts,
                files.path_main + files.umap_tissues_patient_counts,
                files.path_main + files.umap_antigens_patient_counts,
                files.path_main + files.umap_statuses_patient_counts,
                files.path_main + files.umap_studyID_patient_counts,
              ]}
              labels={[
                "Clusters", "Tissues", "Antigens", "Infection Statuses", "Study"
              ]}
            />
          </div>



          <div className='content_title'>DGE, unsupervised</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                files.path_main + files.markers_unsupervised_clusters,
                files.path_main + files.markers_unsupervised_antigens,
                files.path_main + files.markers_unsupervised_statuses
              ]}
              labels={[
                "Clusters", "Antigens", "Infection Statuses"
              ]}
            />
          </div>

          <div className='content_title'>DGE, supervised: Infection Statuses</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                files.path_main + files.markers_supervised_statuses_statuses,
                files.path_main + files.markers_supervised_statuses_clusters,
                files.path_main + files.markers_supervised_statuses_antigens
              ]}
              labels={[
                "Infection Statuses", "Clusters", "Antigens"
              ]}
            />
          </div>

          <div className='content_title'>DGE, supervised: Antigens</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                files.path_main + files.markers_supervised_antigens_antigens,
                files.path_main + files.markers_supervised_antigens_clusters,
                files.path_main + files.markers_supervised_antigens_statuses
              ]}
              labels={[
                "Antigens", "Clusters", "Infection Statuses"
              ]}
            />
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className='modal_select_back' style={{ color: '#AAA', marginBottom: '30px', width: '100%', textAlign: 'left', padding: '0px', display: 'flex', alignItems: 'center' }}>
            <div onClick={() => { button_GoBackToMainPopulaion() }} className='modal_select_back_child'>
              <img src='/back.png' style={{ height: '15px', width: '15px', objectFit: 'contain', marginRight: "5px" }} /> Go Back to the Main Population
            </div>
            <div onClick={() => { app_signOut() }} className='modal_select_back_signout'>
              Sign Out
            </div>
          </div>
          {div_comparison()}
        </>
      )
    }
  }


  const button_GoBackToMainPopulaion = () => {
    setComparisons([]);
    SelectComparison(null);
    SelectPopulation(null);
  }


  const div_comparison = () => {

    if (SelectedComparison != null) {
      var imageBasePath = Data.path[SelectedComparison];

      //
      var reportImages = (arrayImages, tables, index) => arrayImages.map((imageName, imageIndex) => {
        var imgPath = imageBasePath + 'gsea/' + index + '/' + imageName;
        return (
          <div key={imageIndex} className='content_reportImage' onClick={() => { button_toggleModalImagePreview(imgPath, tables[imageIndex]) }}>
            <img src={imgPath} />
          </div>
        )
      })
      //
      const div_gsea = () => Data.gsea[SelectedComparison].results.map((result, index) => {
        var database = result.database;
        var databaseName = data_general.gsea_databases[database].title;
        var databaseSets = data_general.gsea_databases[database].sets;
        var databaseContent = data_general.gsea_databases[database].content;
        var databaseURL = data_general.gsea_databases[database].url;



        var secondPhenotype = Data.second[SelectedComparison];

        return (
          <div key={index} style={{ marginBottom: '150px' }}>

            {/* DESCRIPTION */}

            <div className='content_databaseTitle'>
              Database: {databaseName}
            </div>

            <div className='content_databaseDescription'>
              Description: {databaseContent}
            </div>

            <div className='content_databaseDescription'>
              Number of gene sets: {databaseSets}
            </div>

            {
              databaseURL != "" ?
                (
                  <div className='content_databaseDescription'>
                    URL: {databaseURL}
                  </div>
                )
                :
                (<></>)
            }
            {/*  */}


            {/* ACTUAL RESULTS */}
            <div className='content_reportTitle'>
              Report
            </div>

            {/* PHENOTYPE 1 */}
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '20px' }}>
                <div style={{ width: '7px', height: '7px', borderRadius: '100%', backgroundColor: '#000', marginTop: '20px' }} />
              </div>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <div className='content_reportSubtitle'>
                  Enrichment in phenotype: {Data.first[SelectedComparison]}
                </div>
                <div className='content_reportDescription'>
                  {result.first.up} / {databaseSets} gene sets are upregulated
                </div>
                <div className='content_reportDescription'>
                  {result.first.fdr} gene sets are significant at FDR &lt; 25%
                </div>
                <div className='content_reportDescription'>
                  {result.first.p1} gene sets are significantly enriched at nominal pvalue &lt; 1%
                </div>
                <div className='content_reportDescription'>
                  {result.first.p5} gene sets are significantly enriched at nominal pvalue &lt; 5%
                </div>
                {
                  result.first.up === 0 ? (<></>) : (
                    <div className='content_reportLink' onClick={() => { button_toggleModalImagePreview(null, result.first.fullReport) }}>
                      Show full gene set list
                    </div>
                  )
                }
                <div className='content_reportImagesContainer'>
                  {reportImages(result.first.images, result.first.tables, index)}
                </div>
              </div>
            </div>
            {/*  */}

            {/* PHENOTYPE 2 */}
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', width: '20px' }}>
                <div style={{ width: '7px', height: '7px', borderRadius: '100%', backgroundColor: '#000', marginTop: '20px' }} />
              </div>
              <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                <div className='content_reportSubtitle'>
                  Enrichment in phenotype: {Data.second[SelectedComparison]}
                </div>
                <div className='content_reportDescription'>
                  {result.second.up} / {databaseSets} gene sets are upregulated
                </div>
                <div className='content_reportDescription'>
                  {result.second.fdr} gene sets are significant at FDR &lt; 25%
                </div>
                <div className='content_reportDescription'>
                  {result.second.p1} gene sets are significantly enriched at nominal pvalue &lt; 1%
                </div>
                <div className='content_reportDescription'>
                  {result.second.p5} gene sets are significantly enriched at nominal pvalue &lt; 5%
                </div>
                {
                  result.second.up === 0 ? (<></>) : (
                    <div className='content_reportLink' onClick={() => { button_toggleModalImagePreview(null, result.second.fullReport) }}>
                      Show full gene set list
                    </div>
                  )
                }

                <div className='content_reportImagesContainer'>
                  {reportImages(result.second.images, result.second.tables, index)}
                </div>
              </div>
            </div>
            {/*  */}



            {/*  */}


          </div>
        )
      })


      // 


      return (
        <>
          <div className='content_bigTitle'>
            {Data.population}
          </div>

          <div className='content_subTitle'>
            {Data.comparison[SelectedComparison]}
          </div>

          <div className='content_title'>General information</div>
          <img className='content_generalCount' src={Data.path[SelectedComparison] + "seurat/" + 'general_counts.png'} />

          <div className='content_title'>UMAPS</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                Data.path[SelectedComparison] + "seurat/" + files.umap_clusters,
                Data.path[SelectedComparison] + "seurat/" + files.umap_patients,
                Data.path[SelectedComparison] + "seurat/" + files.umap_tissue,
                Data.vetos[SelectedComparison].includes("antigens") ? null : Data.path[SelectedComparison] + "seurat/" + files.umap_antigens,
                Data.vetos[SelectedComparison].includes("infection_status") ? null : Data.path[SelectedComparison] + "seurat/" + files.umap_statuses,
                Data.path[SelectedComparison] + "seurat/" + files.umap_studyID,
              ]}
              labels={[
                "Clusters", "Patients", "Tissues",
                Data.vetos[SelectedComparison].includes("antigens") ? null : "Antigens",
                Data.vetos[SelectedComparison].includes("infection_status") ? null : "Infection Statuses",
                "Study"
              ]}
            />
          </div>

          <div className='content_title'>Cell Counts</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                Data.path[SelectedComparison] + "seurat/" + files.umap_clusters_cell_counts,
                Data.path[SelectedComparison] + "seurat/" + files.umap_tissues_cell_counts,
                Data.vetos[SelectedComparison].includes("antigens") ? null : Data.path[SelectedComparison] + "seurat/" + files.umap_antigens_cell_counts,
                Data.vetos[SelectedComparison].includes("infection_status") ? null : Data.path[SelectedComparison] + "seurat/" + files.umap_statuses_cell_counts,
                Data.path[SelectedComparison] + "seurat/" + files.umap_studyID_cell_counts,
              ]}
              labels={[
                "Clusters", "Tissues",
                Data.vetos[SelectedComparison].includes("antigens") ? null : "Antigens",
                Data.vetos[SelectedComparison].includes("infection_status") ? null : "Infection Statuses",
                "Study"
              ]}
            />
          </div>

          <div className='content_title'>Patient Counts</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                Data.path[SelectedComparison] + "seurat/" + files.umap_clusters_patient_counts,
                Data.path[SelectedComparison] + "seurat/" + files.umap_tissues_patient_counts,
                Data.vetos[SelectedComparison].includes("antigens") ? null : Data.path[SelectedComparison] + "seurat/" + files.umap_antigens_patient_counts,
                Data.vetos[SelectedComparison].includes("infection_status") ? null : Data.path[SelectedComparison] + "seurat/" + files.umap_statuses_patient_counts,
                Data.path[SelectedComparison] + "seurat/" + files.umap_studyID_patient_counts,
              ]}
              labels={[
                "Clusters", "Tissues",
                Data.vetos[SelectedComparison].includes("antigens") ? null : "Antigens",
                Data.vetos[SelectedComparison].includes("infection_status") ? null : "Infection Statuses",
                "Study"
              ]}
            />
          </div>



          <div className='content_title'>DGE, unsupervised</div>
          <div className='content_tab'>
            <ImageSlideshow
              previewFn={button_toggleModalImagePreview}
              images={[
                Data.path[SelectedComparison] + "seurat/" + files.markers_unsupervised_clusters,
                Data.vetos[SelectedComparison].includes("antigens") ? null : Data.path[SelectedComparison] + "seurat/" + files.markers_unsupervised_antigens,
                Data.vetos[SelectedComparison].includes("infection_status") ? null : Data.path[SelectedComparison] + "seurat/" + files.markers_unsupervised_statuses
              ]}
              labels={[
                "Clusters",
                Data.vetos[SelectedComparison].includes("antigens") ? null : "Antigens",
                Data.vetos[SelectedComparison].includes("infection_status") ? null : "Infection Statuses"
              ]}
            />
          </div>


          {
            Data.vetos[SelectedComparison].includes("infection_status") == false ?
              (
                <>
                  <div className='content_title'>DGE, supervised: Infection Statuses</div>
                  <div className='content_tab'>
                    <ImageSlideshow
                      previewFn={button_toggleModalImagePreview}
                      images={[
                        Data.vetos[SelectedComparison].includes("infection_status") ? null : Data.path[SelectedComparison] + "seurat/" + files.markers_supervised_statuses_statuses,
                        Data.path[SelectedComparison] + "seurat/" + files.markers_supervised_statuses_clusters,
                        Data.vetos[SelectedComparison].includes("antigens") ? null : Data.path[SelectedComparison] + "seurat/" + files.markers_supervised_statuses_antigens
                      ]}
                      labels={[
                        Data.vetos[SelectedComparison].includes("infection_status") ? null : "Infection Statuses",
                        "Clusters",
                        Data.vetos[SelectedComparison].includes("antigens") ? null : "Antigens"
                      ]}
                    />
                  </div>
                </>
              )
              :
              (
                <></>
              )
          }

          {
            Data.vetos[SelectedComparison].includes("antigens") == false ?
              (
                <>
                  <div className='content_title'>DGE, supervised: Antigens</div>
                  <div className='content_tab'>
                    <ImageSlideshow
                      previewFn={button_toggleModalImagePreview}
                      images={[
                        Data.vetos[SelectedComparison].includes("antigens") ? null : Data.path[SelectedComparison] + "seurat/" + files.markers_supervised_antigens_antigens,
                        Data.path[SelectedComparison] + "seurat/" + files.markers_supervised_antigens_clusters,
                        Data.vetos[SelectedComparison].includes("infection_status") ? null : Data.path[SelectedComparison] + "seurat/" + files.markers_supervised_antigens_statuses
                      ]}
                      labels={[
                        Data.vetos[SelectedComparison].includes("antigens") ? null : "Antigens",
                        "Clusters",
                        Data.vetos[SelectedComparison].includes("infection_status") ? null : "Infection Statuses"
                      ]}
                    />
                  </div>
                </>
              )
              :
              (
                <></>
              )
          }




          <div className='content_title'>GSEA</div>
          {
            Data.rankedGenes[SelectedComparison] === "" ? (<></>) : (
              <div className='content_reportLink' style={{ marginTop: '20px' }} onClick={() => { button_toggleModalImagePreview(null, "<div class='rankedGenesListTable'>" + Data.rankedGenes[SelectedComparison] + "</div>") }}>
                Show used ranked-gene list (top 100 positive)
              </div>
            )
          }
          <div className='content_tab'>
            {div_gsea()}
          </div>

        </>
      )
    }
  }


  const modal_ImagePreview = () => {
    if (ModalImagePreview == true) {
      return (
        <div id='ImagePreviewContainer' className='ImagePreviewContainer'>
          <div onClick={() => { button_toggleModalImagePreview() }} style={{ position: 'absolute', top: '10px', fontSize: '22px', textTransform: 'uppercase', color: '#FFF', padding: '15px', cursor: 'pointer', fontWeight: 600 }}>
            close
          </div>
          {/* <div style={{ position: 'fixed', height: '100%', width: '100%' }} onClick={() => { button_toggleModalImagePreview() }} /> */}
          {
            ImagePreview == null ? (<></>) : (
              ImagePreview_htmlToParse === null ?
                (<img src={ImagePreview} style={{ zIndex: 300 }} />)
                :
                (<img src={ImagePreview} style={{ zIndex: 300, width: '30%', height: 'auto' }} />)
            )
          }
          <div>
            {
              ImagePreview_htmlToParse === null ?
                (<></>)
                :
                (
                  <div className='ImagePreview_html' style={{ width: '100%', maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    {parse(ImagePreview_htmlToParse)}
                  </div>
                )
            }
          </div>
        </div>
      )
    }
  }




  // EXECUTIVE FUNCTIONS

  const button_toggleModalImagePreview = (image, html) => {
    var oldStatus = ModalImagePreview;
    if (oldStatus == false) {
      toggleModalImagePreview(!ModalImagePreview);
      setImagePreview(image);
      setImagePreview_htmlToPrase(html);
      setTimeout(() => {
        document.getElementById('ImagePreviewContainer').style.display = 'flex';
        setTimeout(() => {
          document.getElementById('ImagePreviewContainer').style.opacity = 1;
        }, 20)
      }, 20);
    } else {
      document.getElementById('ImagePreviewContainer').style.opacity = 0;
      setTimeout(() => {
        document.getElementById('ImagePreviewContainer').style.display = 'none';
        setTimeout(() => {
          toggleModalImagePreview(!ModalImagePreview);
          setImagePreview(null);
          setImagePreview_htmlToPrase(null);
        }, 20);
      }, 300);
    }
  }

  const changePopulation = (e) => {
    SelectPopulation(e.target.value);

    setLoadingSelecting(true);
    setComparisons([]);
    SelectComparison(null);
    // HERE HERE
    const storage = getStorage();
    const gsReference = ref(storage, 'gs://lauerkimlab.appspot.com/population_' + e.target.value + '.json');
    getDownloadURL(gsReference)
      .then((url) => {
        // console.log(url);
        fetch(url)
          .then(res => res.json())
          .then(out => {
            setData(out);
            setComparisons(data_general.data[e.target.value].comparison);
            setLoadingSelecting(false);
          })
          .catch(err => console.log(err));

      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });

  }
  const changeComparison = (e) => {
    SelectComparison(e.target.value);
    button_toggleModal();
    document.getElementById("content-in").scrollTo(0, 0);
  }

  const button_toggleModal = () => {
    var oldModal = ModalShown;

    if (oldModal == false) {
      toggleModal(!ModalShown);
      SelectPopulation(null);
      setComparisons([]);
      SelectComparison(null);

      setTimeout(() => {
        var modal_select = document.getElementById("modal_select");
        modal_select.style.display = "flex";
        setTimeout(() => {
          modal_select.style.opacity = 1;
        }, 20);
      }, 20)
    } else {
      var modal_select = document.getElementById("modal_select");
      modal_select.style.opacity = 0;
      setTimeout(() => {
        modal_select.style.display = "none";
        setTimeout(() => {
          toggleModal(!ModalShown);
        }, 20)
      }, 300);
    }
  }



  // const onChangePassword = (e) => {
  //   setPassword(e.target.value);
  // }
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  }



  const submitPassword = () => {
    // if (Password == "HBV6@ta_Lauer2022") {
    //   setPasswordPassed(true);
    // } else {
    //   setWrongPassword(true);
    // }
  }

  const submitEmail = () => {
    // CHECK IF EMAIL IS ELIGIBLE
    if (EligibleEmails.includes(Email.toLowerCase()) === false) {
      setCheckYourEmail(false);
      setWrongEmail(true);
    } else {
      setCheckYourEmail(false);
      setWrongEmail(false);
      setLoadingEmail(true);
      const auth = getAuth();
      sendSignInLinkToEmail(auth, Email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          setCheckYourEmail(true);
          setLoadingEmail(false);

          localStorage.setItem('emailForSignIn', Email);

          if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = localStorage.getItem('emailForSignIn');
            // if (!email) {
            //   // User opened the link on a different device. To prevent session fixation
            //   // attacks, ask the user to provide the associated email again. For example:
            //   // email = window.prompt('Please provide your email for confirmation');
            // }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
              .then((result) => {
                // Clear email from storage.
                localStorage.removeItem('emailForSignIn');
                setEmailPassed(true);
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
              })
              .catch((error) => {
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
                console.log(error);
              });
          }
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage);
          console.log(errorCode);
          // ...
        });
    }
  }

  const passEmail = () => {
    if (EmailPassed == true) {
      return (
        <>
          <div className='content' id='content'>
            <div className='content-in' id='content-in'>
              {div_content()}
            </div>
          </div>

          <div className='footer'>
            <div className='button' onClick={() => { button_toggleModal() }}>
              Tap to select a comparison
            </div>
          </div>

          {modal_SelectPopulationComparison()}
          {modal_ImagePreview()}
        </>
      )
    } else if (EmailPassed == false) {
      return (
        <div className='modal_password'>
          {WrongEmail === true ?
            (<div className='wrongpassword'>Email is not eligible. Kindly try with another one.</div>)
            : <></>}

          {LoadingEmail === true ?
            (<div className='loading'>Loading...</div>) : (<></>)
          }
          {CheckYourEmail === true ?
            <div className='checkemail'>An authentication link has been successfully sent to your email. If not received, kindly check your junk folder.</div>
            : <></>}

          {LoadingEmail === false && CheckYourEmail === false ?
            (<>
              <input type='text' placeholder='Type email...' onChange={(e) => { onChangeEmail(e) }} />
              <div className='button' style={{ maxWidth: '400px' }} onClick={() => { submitEmail() }}>
                Submit
              </div>
            </>) : (<></>)
          }

        </div>
      )
    } else {
      return (
        <div className='modal_password'>
          <div className='loading'>Loading...</div>
        </div>
      )
    }
  }

  const app_signOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
      window.location.reload();
    }).catch((error) => {
      // An error happened.
    });
  }

  return (
    <div className="App">
      {passEmail()}
    </div>
  );
}

export default App;
